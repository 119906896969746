import React, { useState } from 'react'
import { FaFlag } from "react-icons/fa6";
import Flags from '../assets/flags.png'
import { Link, useNavigate } from 'react-router-dom';
import { collection, addDoc, getDocs } from "firebase/firestore";
import { contactRef, db, onSubmitForm } from './firebase';
import { ToastContainer, toast } from 'react-toastify';
import Logo from '../assets/logo.png'

const Home = () => {

    const [state, setState] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",



    })
    const navigate = useNavigate()

    const onSubmitForm = async (e) => {
        e.preventDefault()

        try {
            const docRef = await addDoc(collection(db, "contactus"), {
                name: state.name,
                email: state.email,
                subject: state.subject,
                message: state.message,
            });
            if (docRef) {
                setState({
                    name: "",
                    email: "",
                    subject: "",
                    message: "",
                })
                return toast.success('Form Submitted Successfully', { position: "top-center", })
            }
            else {
                return toast.error('An Error Occured', { position: "top-center", })
            }
        } catch (e) {
            console.error("Error adding document: ", e);
        }

    }

    const onChange = (e) => {
        const { value, name } = e.target
        setState({ ...state, [name]: value })
    }

    return (
        <div >
            <ToastContainer />


            <nav className="bg-white-300 border-white-200 light:bg-white-900">
                <div className="max-w-6xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <a className="flex items-center space-x-3 rtl:space-x-reverse">
                        <Link to="/" className="flex items-center self-center text-black-700 no-underline text-3xl font-semibold">
                          <img src={Logo} className="w-20 h-auto" alt="Flowbite Logo" />
                           <span className='text-orange-800' style={{color:"#c51b1b"}}>Autos</span>Inspect
                        </Link>
                    </a>
                    <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                        aria-controls="navbar-default"
                        aria-expanded="false"
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                    <div className=" w-full block  md:w-auto" id="navbar-default">
                        <ul  className="secondary-color font-medium flex flex-col p-4 md:p-0 mt-4 border border-white-100 rounded-lg bg-white-300 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white-300 light:bg-white-800 md:dark:bg-light-900 light:border-white-700">

                            <li>
                                <Link  to="/" className="secondary-color block py-2 px-3 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-800 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Home</Link>
                            </li>
                            <li>
                                <Link to="/how" className="secondary-color block py-2 px-3 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-800 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">How To Order</Link>
                            </li>
                            <li>
                                <a href="#" className="secondary-color py-2 px-3 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-800 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent flex items-center"><FaFlag className='mr-2' />
                                    EN
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>


            <div className='bg-image'>





    


                <div className=" w-1/3 flex  min-h-12 justify-around text-white margin-banner"  >
                <button onClick={()=>navigate("/search")}  className="how-to-order  text-white w-full bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm  sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Get Report</button>

                </div>


            </div>



            <div
                className="max-w-6xl mx-auto flex flex-wrap lg:grid grid-cols-3 gap-12 justify-center justify-items-center items-center pt-32 pb-2 bg-gr"
            >


                <div className="info info-3 flex flex-col items-center p-14 " data-aos="fade-down">
                    <div className="text-heading text-center pb-12">
                        <p className="text-3xl font-bold">Check <span className="font-bold secondary-color">Vehicle History</span> in three easy steps</p>
                    </div>

                    <div className="text-list flex items-center mt-4">
                        <div className="img"><img src="https://autodatarecords.com/public/frontend/img/keyboard.svg" alt="" className="h-6" /></div>
                        <div className="text ml-2 text-xl">Enter the VIN number</div>
                    </div>
                    <div className="text-list flex items-center mt-2">
                        <div className="img"><img src="https://autodatarecords.com/public/frontend/img/cards.svg" alt="" className="h-6" /></div>
                        <div className="text ml-2 text-xl">Choose VIN report</div>
                    </div>
                    <div className="text-list flex items-center mt-2">
                        <div className="img"><img src="https://autodatarecords.com/public/frontend/img/lightbulb.svg" alt="" className="h-6" /></div>
                        <div className="text ml-2 text-xl  ">Find out more</div>
                    </div>
                </div>


                <div className="info info-2" data-aos="fade-up">
                    <img src="https://autodatarecords.com/public/frontend/img/main-report-availability-1.png" className="" style={{ width: '55vw', height: '60vh' }} alt="" />
                </div>


                <div className="info info-1" data-aos="fade-down">
                    <div className="textbox mt-4 text-hed">
                        <p className="text-lg">Proven and reliable information</p>
                    </div>
                    <div className="textbox mt-4 text-hed text-lg">
                        <p className="">Online support 24/7 Available</p>
                    </div>
                    <div className="textbox mt-4 text-hed text-lg leading-6">
                        <p className='leading-6'>Data for vehicles from all over United States, Canada, Australia, France, Germany, Ireland, Italy, Netherlands, New Zealand, Portugal, Spain, Sweden, United Kingdom, Denmark and Luxembourg. </p>
                    </div>
                </div>

            </div>




            <div className="max-w-6xl flex mx-auto p-16">
                <div class="info info">
                    <div class="text-heading font-bold" data-aos="fade-down">
                        <p class="heading-3 text-center text-2xl bl-color mb-4">Why use <span className='secondary-color'>AUTOS INSPECT ?</span></p>
                    </div>

                    <div class="text-list" data-aos="zoom-in-up">
                        <div class="text text-1 animate__animated animate__backInUp text-center text-lg ">
                            <b >Just purchase the VIN report to prevent purchasing a damaged
                                vehicle.</b>
                            Make an informed decision rather than putting your and your loved
                            ones' safety in jeopardy. You can discover what the vehicle
                            history conceals by just entering the VIN in the search field. If
                            available, you can even view old photos of the vehicle that show
                            damage, recorded miles, and more.
                            <b>
                                When purchasing a car on the secondary market, our vehicle
                                history report greatly assists.</b>
                        </div>
                    </div>
                </div>
            </div>




            <div class="max-w-6xl mx-auto  lg:grid grid-cols-4 gap-10 p-4 justify-items-center items-center bg-gr ">
                <div class="def-card" data-aos="flip-left">
                    <div class="img flex justify-center"><img src="https://autodatarecords.com/public/frontend/img/ico-equip-min.svg" alt="" /></div>
                    <div class="heading-2 bl-color font-bold text-2xl text-center secondary-color ">Vehicle History</div>

                    <div class="text-1 text-xl text-center bl-color">
                        Information about offers sales and vehicle owners
                    </div>
                </div>

                <div class="def-card" data-aos="flip-up">
                    <div class="img flex justify-center"><img src="https://autodatarecords.com/public/frontend/img/ico-damages-min.svg" alt="" /></div>
                    <div class="heading-2 bl-color font-bold text-2xl text-center secondary-color">Damages</div>
                    <div class="text-xl text-center bl-color ">
                        Information about events and collisions with a vehicle
                    </div>
                </div>
                <div class="def-card" data-aos="flip-up">
                    <div class="img flex justify-center"><img src="https://autodatarecords.com/public/frontend/img/ico-equip-min.svg" alt="" /></div>
                    <div class="heading-2 bl-color font-bold text-2xl text-center secondary-color">Mileage reading</div>
                    <div class="text-1 text-xl text-center bl-color">Chronological list of mileage readings</div>
                </div>
                <div class="def-card" data-aos="flip-right">
                    <div class="img flex justify-center"><img src="https://autodatarecords.com/public/frontend/img/ico-service-min.svg" alt="" /></div>
                    <div class="heading-2 bl-color font-bold text-2xl text-center secondary-color">Vehicle inspections</div>
                    <div class="text-1 text-xl text-center bl-color">
                        Information on completed surveys / inspections vehicle
                    </div>
                </div>

                <div class="def-card" data-aos="flip-left">
                    <div class="img flex justify-center"><img src="https://autodatarecords.com/public/frontend/img/ico-stolen-min.svg" alt="" /></div>
                    <div class="heading-2 bl-color font-bold text-2xl text-center secondary-color">Information theft</div>
                    <div class="text-1 text-xl text-center bl-color">
                        Checking international vehicle records sought
                    </div>
                </div>
                <div class="def-card" data-aos="flip-down">
                    <div class="img flex justify-center"><img src="https://autodatarecords.com/public/frontend/img/ico-photos-min.svg" alt="" /></div>
                    <div class="heading-2 bl-color font-bold text-2xl text-center secondary-color">Archival photo</div>
                    <div class="text-1 text-xl text-center bl-color">
                        Archival photographic documentation of the vehicle
                    </div>
                </div>
                <div class="def-card" data-aos="flip-down">
                    <div class="img flex justify-center"><img src="https://autodatarecords.com/public/frontend/img/ico-decoder-min.svg" alt="" /></div>
                    <div class="heading-2 bl-color font-bold text-2xl text-center secondary-color">Technical data</div>
                    <div class="text-1 text-xl text-center bl-color">List of vehicle equipment options factory</div>
                </div>
                <div class="def-card" data-aos="flip-right">
                    <div class="img flex justify-center"><img src="https://autodatarecords.com/public/frontend/img/ico-decoder-min.svg" alt="" /></div>
                    <div class="heading-2 bl-color font-bold text-2xl text-center secondary-color">Analysis VIN</div>
                    <div class="text-1 text-xl text-center bl-color">Checksum verification and validation VIN</div>
                </div>
            </div>



            <div className="max-w-6xl flex mx-auto p-16">
                <div class="info info">
                    <div class="text-heading font-bold" data-aos="fade-down">
                        <p class="heading-3 text-center text-2xl bl-color mb-4">How to decode the <span className='secondary-color'>VIN number?</span>?</p>
                    </div>

                    <div class="text-list" data-aos="zoom-in-up">
                        <div class="text text-1 animate__animated animate__backInUp text-center text-lg ">
                        The comprehensive data recorded by the car manufacturer will be available to you via our<b> VIN decoder</b>. 
                        With the help of this, you can verify the equipment listed on the vehicle's VIN and obtain trustworthy 
                        information about the car that interests you. With the help of this, you can verify the equipment listed 
                        on the vehicle's VIN and obtain trustworthy information about the car that interests you.
                         To obtain decoded information, just input the VIN number into our system.
                          It will take a few seconds to process. You select intriguing options based on the report,
                           and you can look into the vehicle's place of origin. Choose the most convenient payment option
                            from the list of available methods. You'll receive an email notification on the created<b> Vehicle History Report</b> shortly after you make payment.
                        </div>
                    </div>
                </div>
            </div>





            <div className='max-w-7xl mx-auto  bg-gr pt-16'>

                <div className="text-heading text-center mb-4">
                    <p className="text-center font-medium text-2xl bl-colour secondary-color" style={{fontSize:"36px",fontWeight:"800"}}>PRICING</p>
                </div>

                <div className='flex flex-wrap gap-6 pl-4 ' >


                    <div data-aos="zoom-out-up" className="bg-black w-96 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    
                        <div className="p-5">
                                <h3 className="text-center mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white heading-font-size secondary-color">Startup</h3>
                                <h3 className="text-center mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white price-font-size secondary-color">$25-$42</h3>
                                <p className="text-center mb-3 font-normal  text-white ">Delivery Duration: 12 Hours</p>
                                <hr class="w-48 h-1 mx-auto my-3 bg-white border-0 rounded md:my-8"/>
                                <p className="text-center mb-3 font-normal  text-white ">Vehicle Overview</p>
                                <p className="text-center mb-3 font-normal  text-white ">Market Value</p>
                                <p className="text-center mb-3 font-normal  text-white ">Vehicle Specifications</p>
                                <p className="text-center mb-3 font-normal  text-white ">Sales Listing</p>
                                <p className="text-center mb-3 font-normal  text-white ">Accident Record</p>
                                <p className="text-center mb-3 font-normal  text-white ">Theft Record</p>
                                <div className='text-center'>
                        <button onClick={()=>navigate("/search")} style={{ width: "50%" }} className=" text-white w-full bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm  sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Get Started</button>
                    </div>




                        </div>
                    </div>



                     <div data-aos="zoom-out-up" className="bg-black w-96 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                     
                        <div className="p-5">
                                <h3 className="text-center mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white heading-font-size secondary-color">Instant Elite</h3>
                                <h3 className="text-center mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white price-font-size secondary-color">$35-$50</h3>
                                <p className="text-center mb-3 font-normal  text-white ">Delivery Duration: 30 Minutes</p>
                                <hr class="w-48 h-1 mx-auto my-3 bg-white border-0 rounded md:my-8"/>
                                <p className="text-center mb-3 font-normal  text-white ">Vehicle Overview</p>
                                <p className="text-center mb-3 font-normal  text-white ">Theft Record</p>
                                <p className="text-center mb-3 font-normal  text-white ">Title Record</p>
                                <p className="text-center mb-3 font-normal  text-white ">Market Value</p>
                                <p className="text-center mb-3 font-normal  text-white ">Accident Record</p>
                                <p className="text-center mb-3 font-normal  text-white ">Salvage</p>
                                <p className="text-center mb-3 font-normal  text-white ">Impounds</p>

                                <div className='text-center'>
                        <button onClick={()=>navigate("/search")} style={{ width: "50%" }} className=" text-white w-full bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm  sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Get Started</button>
                    </div>




                        </div>
                    </div>


                    <div data-aos="zoom-out-up" className="bg-black w-96 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-5">
                                <h3 className="text-center mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white heading-font-size secondary-color">Premium</h3>
                                <h3 className="text-center mb-2 text-base font-bold tracking-tight text-gray-900 dark:text-white price-font-size secondary-color">$42-$60</h3>
                                <p className="text-center mb-3 font-normal  text-white ">Delivery Duration: 60 Minutes</p>
                                <hr class="w-48 h-1 mx-auto my-3 bg-white border-0 rounded md:my-8"/>
                                <p className="text-center mb-3 font-normal  text-white ">Buy one get one Free Report for Lifetime</p>
                                <p className="text-center mb-3 font-normal  text-white ">High Quality Car Images</p>
                                <p className="text-center mb-3 font-normal  text-white ">Vehicle Specifications</p>
                                <p className="text-center mb-3 font-normal  text-white ">Market Value</p>
                                <p className="text-center mb-3 font-normal  text-white ">Accident Record</p>
                                <p className="text-center mb-3 font-normal  text-white ">Salvage</p>

                                <p className="text-center mb-3 font-normal  text-white ">Theft Record</p>
                                <div className='text-center'>
                        <button onClick={()=>navigate("/search")} style={{ width: "50%" }} className=" text-white w-full bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm  sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Get Started</button>
                    </div>




                        </div>
                    </div>



                </div>

            
            </div>



            <div className='max-w-6xl mx-auto pt-16 px-10' data-aos="flip-left" >
                <div className="sm:px-8 lg:px-24">
                    <p className='text-xl'>Let us know what questions you have and how may we can help.
                        <br />
                        <b>Note: </b>Please provide your VIN number too if you have questions reguarding your
                        report.
                    </p>
                </div>
                <form className="max-w-screen-xl mx-auto sm:px-4 lg:px-24" onSubmit={onSubmitForm} >
                    <div className="mb-5">
                        <input onChange={onChange} value={state.name} name="name" type="text" id="name" className="bg-black bg-black-50 border border-black-300 text-black-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-white" placeholder="Name" required />
                    </div>

                    <div className="mb-5">
                        <input onChange={onChange} value={state.email} type="email" name="email" id="email" className="bg-black bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-white" placeholder="Email" required />
                    </div>


                    <div className="mb-5">
                        <input onChange={onChange} value={state.subject} type="text" name="subject" id="subject" className="bg-black bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-white" placeholder="Subject" required />
                    </div>

                    <div className="mb-5">

                        <textarea onChange={onChange} value={state.message} id="w3review" name="message" className="bg-black bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-white" placeholder="Leave Us a message" rows="4" cols="50">

                        </textarea>
                    </div>

                    <div>
                        <button type="submit" style={{ width: "100%" }} className="text-white w-full bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm  sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Submit</button>
                    </div>
                </form>
            </div>

            <footer className='max-w-6xl mx-auto pt-16 flex justify-center'>
                <div >

                    <div className="flex justify-center ">
                        <img src={Flags} />
                    </div>
                    <div className="copyrights">
                        &copy; 2016 Autos Inspect All rights reserved.
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Home