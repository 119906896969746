import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const CheckoutForm = ({onSubmitForm}) => {
  const stripe = useStripe();
  const elements = useElements();

  return (
    <form onSubmit={onSubmitForm}>
      <label>Card details</label>
      <CardElement className="card-element" />
      {/* <button style={{border:'solid black 2px'}} disabled={!stripe}>Pay</button> */}
      <div>
        <button disabled={!stripe}  type="submit" style={{ width: "100%" }} className="mt-5 text-white w-full bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm  sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Pay</button>
      </div>
    </form>
  );

}

export default CheckoutForm