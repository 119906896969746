import React,{useState} from 'react'
import { FaFlag } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.png'

const Howtoorder = () => {
    const navigate = useNavigate()
    const [vimNumber,setVim]=useState('')

    return (
        <div>

<nav className="bg-white-300 border-white-200 light:bg-white-900">
                <div className="max-w-6xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <a className="flex items-center space-x-3 rtl:space-x-reverse">
                        <Link to="/" className="flex items-center self-center text-black-700 no-underline text-3xl font-semibold">
                          <img src={Logo} className="w-20 h-auto" alt="Flowbite Logo" />
                           <span className='text-orange-800' style={{color:"#c51b1b"}}>Autos</span>Inspect
                        </Link>
                    </a>
                    <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                        aria-controls="navbar-default"
                        aria-expanded="false"
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                    <div className=" w-full block  md:w-auto" id="navbar-default">
                        <ul  className="secondary-color font-medium flex flex-col p-4 md:p-0 mt-4 border border-white-100 rounded-lg bg-white-300 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white-300 light:bg-white-800 md:dark:bg-light-900 light:border-white-700">

                            <li>
                                <Link  to="/" className="secondary-color block py-2 px-3 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-800 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Home</Link>
                            </li>
                            <li>
                                <Link to="/how" className="secondary-color block py-2 px-3 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-800 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">How To Order</Link>
                            </li>
                            <li>
                                <a href="#" className="secondary-color py-2 px-3 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-800 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent flex items-center"><FaFlag className='mr-2' />
                                    EN
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>








            <div className='nav-red' style={{background:"#c51b1b"}}>
                <div className=" max-w-6xl mx-auto sm:block  md:flex text-lg p-4 justify-between items-center">
                    <div className="crumbs text-gray-400">
                        <Link to="/" className='text-white'>Home</Link> » How To Order
                    </div>
                    <form method="POST" className=" flex justify-center align-middle items-center " onSubmit={() => navigate('/search',{state:vimNumber})}>
                        <input required type="hidden" name="_token" value="h1OxgnC8ZvZvfQABHuMXaoa38yeR10qisH1x0gZr" />
                        <input onChange={(e)=>setVim(e.target.value)} required className="h-12 w-80 border-0 border-solid border-gray-300 rounded-l-full text-center uppercase text-lg font-semibold text-gray-700" type="text" placeholder="Enter Vin" name="vin" />
                        <button type="submit" className="h-12 w-12 border-none flex justify-center  items-center rounded-r-full gradient-btn ">
                            <img src="https://autodatarecords.com/public/frontend/img/magnifying-glass.png" style={{ width: "2vw", height: "auto" }} alt="" />
                        </button>
                    </form>
                </div>
            </div>




            <div className="max-w-6xl flex mx-auto p-16 bg-gr mt-4">
                <div class="info info">
                    <div class="text-heading font-bold" data-aos="fade-down">
                        <p class="heading-3  text-4xl bl-color mb-4 secondary-color">
                            How to order a vehicle history report
                        </p>
                    </div>

                    <div class="text-list" data-aos="zoom-in-up">
                        <div class="text text-1 font-semibold animate__animated animate__backInUp text-lg ">
                        No matter how you found us—through the blog, our home page, or a search for VIN or car history—you 
                        found the Autos Inspect website. It doesn't matter; we've included several boxes where you can
                         check a VIN number for your convenience. This takes us to the initial phase.
                        </div>
                    </div>
                </div>
            </div>






            <div className="max-w-6xl flex mx-auto p-16 justify-center">
                <div class="info info">
                    <div class="text-heading font-bold" data-aos="fade-down">
                        <p class="heading-3 text-4xl bl-color mb-4 text-center secondary-color">Step 1: Enter your VIN number</p>
                    </div>

                    <div class="text-list" data-aos="zoom-in-up">
                        <div class="text text-1 text-center animate__animated animate__backInUp text-center text-lg ">
                            Enter 17 characters in the appropriate box and click “Check”.
                        </div>
                    </div>
                </div>
            </div>


          

            {/* <div className="max-w-6xl flex mx-auto p-16 bg-gr">
                <div className="info info">
                    
                    <div className="text-heading font-bold" data-aos="fade-down">
                        <p className="heading-3  text-4xl bl-color mb-4 secondary-color">
                            How to order a vehicle history report
                        </p>
                    </div>

                    <div className="text-list" data-aos="zoom-in-up">
                        <div className="text text-1 font-semibold animate__animated animate__backInUp text-lg ">
                            However you arrived to the AutoDataRecords website – through
                            the blog, through our home page or as a result of searching
                            information about VIN or vehicle history. It doesn’t matter –
                            for your convenience we have provided a number of boxes where you
                            can check a VIN number. This brings us to the first step.
                        </div>
                    </div>
                </div>
            </div> */}


<div className="max-w-6xl flex mx-auto p-16">
                <div className="info info">
                    <div className="text-heading font-bold " data-aos="fade-down">
                        <p className="heading-3  text-4xl bl-color mb-4 text-center secondary-color">
                        Step 2: See what data our reports contain
                        </p>
                    </div>

                    <div className="text-list" data-aos="zoom-in-up">
                        <div className="text text-1 text-center font-semibold animate__animated animate__backInUp text-lg ">
                        You wouldn't be entering into an uninformed contract. You may view standard details like the make, 
                        model, and year of production in addition to more specifics like event history, mileage, and information 
                        from databases of stolen vehicles, depending on the car. Simply input a VIN number at the top of the page
                         to check it out for yourself. Note: The post-purchase report will not include any data if there is no data
                          available at this time. You can choose from a variety of report types here. You will need to pick an additional
                           choice if your future vehicle was imported, say from Belgium, the Netherlands, or the USA. A report with more 
                           information from these nations can be purchased.
                        </div>
                    </div>
                </div>
            </div>



            <div className="max-w-6xl flex mx-auto p-16">
                <div className="info info">
                    <div className="text-heading font-bold " data-aos="fade-down">
                        <p className="heading-3  text-4xl bl-color mb-4 text-center secondary-color">
                            Step 3: Pay for the selected vehicle history report
                        </p>
                    </div>

                    <div className="text-list" data-aos="zoom-in-up">
                        <div className="text text-1 text-center font-semibold animate__animated animate__backInUp text-lg ">
                        Choose a payment option first, then input any discount or PREPAID codes you may have. 
                        You'll be able to access your account by entering your email address. A plethora of practical
                         payment options are available. Following your selection, adhere to the on-screen directions.
                          A traditional bank transfer may take longer to process a payment than a typical two to three 
                          minute payment, particularly on weekends and holidays. In the event that this is your first purchase, 
                          you will also receive a follow-up email including your account password as soon as your payment is 
                          made successfully. Check the SPAM folder as well if, as a first-time customer, you are unable to locate
                           the email containing your password
                        </div>
                    </div>
                </div>
            </div>




        </div>
    )
}

export default Howtoorder