import React from 'react'
import { Link } from 'react-router-dom'
import { FaFlag } from "react-icons/fa6";
import Flags from '../assets/flags.png'
import { useNavigate,useLocation } from 'react-router-dom';
import Logo from '../assets/logo.png'

const Searchpage = () => {

    const navigate = useNavigate()
    const location=useLocation()

    return (
        <div>
       <nav className="bg-white-300 border-white-200 light:bg-white-900">
                <div className="max-w-6xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <a className="flex items-center space-x-3 rtl:space-x-reverse">
                        <Link to="/" className="flex items-center self-center text-black-700 no-underline text-3xl font-semibold">
                          <img src={Logo} className="w-20 h-auto" alt="Flowbite Logo" />
                           <span className='text-orange-800' style={{color:"#c51b1b"}}>Autos</span>Inspect
                        </Link>
                    </a>
                    <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                        aria-controls="navbar-default"
                        aria-expanded="false"
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                    <div className=" w-full block  md:w-auto" id="navbar-default">
                        <ul  className="secondary-color font-medium flex flex-col p-4 md:p-0 mt-4 border border-white-100 rounded-lg bg-white-300 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white-300 light:bg-white-800 md:dark:bg-light-900 light:border-white-700">

                            <li>
                                <Link  to="/" className="secondary-color block py-2 px-3 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-800 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Home</Link>
                            </li>
                            <li>
                                <Link to="/how" className="secondary-color block py-2 px-3 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-800 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">How To Order</Link>
                            </li>
                            <li>
                                <a href="#" className="secondary-color py-2 px-3 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-800 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent flex items-center"><FaFlag className='mr-2' />
                                    EN
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>


            <div className='mb-10 mt-4'>
                <p className='text-3xl text-center'>Choose Your Country</p>
            </div>
            <div className="flex justify-evenly gap-24"  style={{flexWrap:"wrap"}}>
                <input type="hidden" name="_token" value="4EHL8EtKseCtMOJkHtAc7BxjcmTqINpVuJENO6Sf" />
                <div className="flag-form-div items-center">

                    <label for="flag1">
                    <div className="input-flag-btn" onClick={() => navigate('/order-report/US',{state:location.state})}>
                            <div className="flag-circle ">
                                <img src="https://www.countryflags.com/wp-content/uploads/united-states-of-america-flag-png-large.png" alt="" />
                            </div>
                            <div className="flagname text-center ">United States of America</div>
                        </div>
                    </label>
                </div>


                <div className="flag-form-div">

                    <label for="flag2">

                    <div className="input-flag-btn" onClick={() => navigate('/order-report/US',{state:location.state})}>
                            <div className="flag-circle">
                                <img src="https://www.countryflags.com/wp-content/uploads/united-kingdom-flag-png-large.png" alt="" />
                            </div>
                            <div className="flagname">United Kingdom</div>
                        </div>
                    </label>
                </div>

                <div className="flag-form-div">

                    <label for="flag3">
                    <div className="input-flag-btn" onClick={() => navigate('/order-report/US',{state:location.state})}>
                            <div className="flag-circle">
                                <img src="https://www.countryflags.com/wp-content/uploads/ireland-flag-png-large.png" alt="" />
                            </div>
                            <div className="flagname">Ireland</div>
                        </div>
                    </label>
                </div>
                <div className="flag-form-div">

                    <label for="flag4 ">
                    <div className="input-flag-btn" onClick={() => navigate('/order-report/US',{state:location.state})}>
                            <div className="flag-circle">
                                <img src="https://www.countryflags.com/wp-content/uploads/canada-flag-png-large.png" alt="" />
                            </div>
                            <div className="flagname">Canada</div>
                        </div>
                    </label>
                </div>

            </div>







            <footer className='max-w-6xl mx-auto pt-16 flex justify-center'>
                <div >

                    <div className="flex justify-center ">
                        <img src={Flags} />
                    </div>
                    <div className="copyrights">
                    &copy; 2016 Autos Inspect All rights reserved.
                    </div>
                </div>
            </footer>


        </div>
    )
}

export default Searchpage